import {lazy, useEffect} from 'react';
import {isEmpty} from 'lodash';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter, Switch, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
// Import Sentry
import * as Sentry from '@sentry/react';

import history from './helpers/router/history';
import ProtectedRoute from './helpers/router/protected-route';
import {initializeApp} from './reducers/auth';
import {acceptInvitation} from './reducers/billing';
import {hasTokensStored} from './helpers/auth';
import {
  SINGLE_ANALYSIS_SHARED,
  SINGLE_VIDEO_ANALYSIS_SHARED,
  LOGIN,
  AUTH,
  COMPANY_ACCEPT
} from './constants/routes';
import Intercom from './components/Intercom';
// import UserTrackingGTM from './components/UserTrackingGTM';
import AppLoader from './components/Spinners/AppLoader';
import {ScrollToTop} from './helpers/router';
import UserpilotWrapper from './components/Userpilot';

import 'react-toastify/dist/ReactToastify.css';
import PartneroScript from './services/partnero';
import UserTrackingGA4 from './components/Tracking/UserTrackingGA4';

const Auth = lazy(() => lazyRetry(() => import('./Pages/Unauthenticated')));

const Main = lazy(() => lazyRetry(() => import('./Pages/Authenticated')));

/* eslint-disable-next-line max-len */
const SingleAnalysis = lazy(() =>
  lazyRetry(() => import('./Pages/Authenticated/MyAnalysis/SingleAnalysis'))
);

const SingleVideoAnalysis = lazy(() =>
  lazyRetry(() => import('./Pages/Authenticated/MyVideos/SingleVideoAnalysis'))
);

const RETRIES = 5;

const INTERVAL_MS = 1000;

const lazyRetry = function (componentImport, retriesLeft = RETRIES, interval = INTERVAL_MS) {
  return new Promise((resolve, reject) => {
    componentImport()
      .then((component) => {
        resolve(component);
      })
      .catch((error) => {
        setTimeout(() => {
          // eslint-disable-next-line no-magic-numbers
          if (retriesLeft === 1) {
            reject(error);

            return;
          }
          // eslint-disable-next-line no-magic-numbers
          lazyRetry(componentImport, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

const InvitationHandler = ({path, acceptInviteCallback, isAuthenticated}) => {
  useEffect(() => {
    const handleInvitation = async () => {
      if (path.startsWith(COMPANY_ACCEPT)) {
        const token = path.split(COMPANY_ACCEPT)[1];

        await acceptInviteCallback({token, isAuthenticated});

        history.push(`${LOGIN}/?invitation=true`);
        window.location.reload();
      }
    };

    handleInvitation();
  }, [path, acceptInviteCallback, isAuthenticated]);

  return null;
};

const AppRouter = ({
  isAuthenticated, location, bootApp, appBooting, onAcceptInvitation
}) => {
  useEffect(() => {
    bootApp();
  }, [bootApp]);

  useEffect(() => {
    const path = location.pathname;

    if (
      isAuthenticated &&
      path.startsWith('/auth') &&
      path !== '/auth/logout' &&
      hasTokensStored()
    ) {
      history.push('/');
    }
    // APPSUMO BLOCK
    // if (
    //   isAuthenticated &&
    //   path.startsWith('/appsumo') &&
    //   hasTokensStored()
    // ) {
    //   history.push(`${ACCOUNT}#appsumo-code`);
    // }
  }, [location, isAuthenticated]);

  if (appBooting) {
    return <AppLoader />;
  }

  return (
    <>
      <UserpilotWrapper isAuthenticated={isAuthenticated} />
      <Intercom isAuthenticated={isAuthenticated} />
      <UserTrackingGA4 isAuthenticated={isAuthenticated} />
      <PartneroScript />
      {/* <UserTrackingGTM isAuthenticated={isAuthenticated} /> */}
      <InvitationHandler
        path={location.pathname}
        acceptInviteCallback={onAcceptInvitation}
        isAuthenticated={isAuthenticated}
      />
      <ScrollToTop />
      <Switch>
        <Route path={[AUTH]} component={Auth} />
        <Route exact path={SINGLE_ANALYSIS_SHARED} component={SingleAnalysis} />
        <Route exact path={SINGLE_VIDEO_ANALYSIS_SHARED} component={SingleVideoAnalysis} />
        <ProtectedRoute path="/" component={Main} />
      </Switch>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnVisibilityChange
        draggable
        rtl={false}
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

const enhance = compose(
  withRouter,
  connect(
    (state) => ({
      l: state.settings.language,

      appBooting: state.settings.appBooting,
      isAuthenticated: !isEmpty(state.settings.profile.data) && hasTokensStored()
    }),
    {
      bootApp: initializeApp,
      onAcceptInvitation: acceptInvitation
    }
  )
);

export default Sentry.withProfiler(enhance(AppRouter));
