export const ALWAYS_ALLOWED_ACTION_TYPES = [
  // needed to load app
  'persist',
  'SETTINGS',
  // needed to load public elements
  'DEMO',
  'STUDIES/TOGGLE_FETCHING',
  'STUDIES/SET_DETAILS',
  'STUDIES/CLEAR_DETAILS',
  // needed to log out user after signing-up / before confirmation
  'AUTH/LOGOUT',
  'STUDIES/SET_ANALYSIS_MAP',
  'STUDIES/SHOW_AOIS_LAYER',
  'STUDIES/SET_VIEWPORT_VALUES',
  'STUDIES/RESET_VIEWPORT_VALUES',
  'STUDIES/SHOW_VIEWPORT_LAYER'
];
